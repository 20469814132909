// store.js
import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)

// window.isCompany = "469";//"469";//
// window.isComftp= "yiwangshengpai";// "yiwangshengpai";//
// window.isHttpName = "https://css.n21ce.com";//"api3.n21ce.com";//

export default new Vuex.Store({
  // 全局状态  初始值
  state: {
    isCompany: 0, //公司编号
    isComftp: "", //公司ftp
    isHttpName: "https://css.n21ce.com",//公司域名
    isImgUrl: "http://images2.n21ce.com/",
    isImgUrlxcx: "https://css.n21ce.com/xcximage/",
  },
  // 加工数据，对数据进行处理
  getters:{},
  // 用来同步修改state内的值，修改num的值
  mutations: {
    updateIsCompany(state,isCompany){
      state.isCompany = isCompany
    },
    updateIsComftp(state,isComftp){
      state.isComftp = isComftp
    },
  },
  actions: {},
  modules: {}
})