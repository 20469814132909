<template>
  <div id="app">
    <!-- <HelloWorld/> -->
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  provide () { // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true // 控制视图是否显示的变量
    }
  },
  name: 'App',
  components: {
    // HelloWorld
  },
  methods: {
    reload () {
      this.isRouterAlive = false // 先关闭，
      setTimeout(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
body,html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  /* background: #f9f9f9 !important; */
  height: 100%;
  width: 100%;
}
/*重置样式*/
* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
</style>
