/** 
 * api接口的统一出口
 */
// 接口
import ysApi from '@/api/ysApi';
// 其他模块的接口……

// 导出接口
export default {
	ysApi,
	SignalR: "https://webapi.n21ce.com"
	// ……
}
