/**
 * webapi公共模块接口列表
 */
import axios from '@/util/http'; // 导入http中创建的axios实例
import base from './base'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const webapi = {
	// webapi.n21ce.com
	// 获取限时拍详情   
	GetZcDetailsByChange(params) {
		return axios.post(`${base.webapi}/api/zhuanchang/GetZcDetailsByChange?${params}`);
	},
	// 获取互动拍历史直播过程
	GetRecordList(params) {
		return axios.get(`${base.webapi}/api/RecordMesage/GetRecordList?${params}`);
	},
	// 获取公司信息
	GetCompanyInfor(params){
		return axios.get(`${base.webapi}/api/Company/GetCompanyInfor?${params}`);
	},
	// 获取多个拍卖会数据
	GetListAuction(params){
		return axios.post(`${base.webapi}/api/ElectronicLot/GetListAuction`,params);
	},
	// 判断是否是互动拍主持人
	
	GetLiveModel(params){
		return axios.get(`${base.webapi}/api/Interaction/GetLiveModel?${params}`);
	},
	// 判断是否是同步拍主持人
	GetModel(params){
		return axios.get(`${base.webapi}/api/Temporary/GetModel?${params}`);
	},
	// 释放号牌
	RemoveTBCacheData(params,config){
		return axios.post(`${base.webapi}/api/Grade/RemoveTBCacheData`,params,config);
	},
	// 互动拍/同步拍固定保证金领牌
	FixedRandomGrade(params){
		return axios.get(`${base.webapi}/api/Grade/FixedRandomGrade?${params}`);
	},
	// 互动拍/同步拍动态保证金领牌
	GenerateListBidNumber(params){
		return axios.get(`${base.webapi}/api/zhuanchang/GenerateListBidNumber?${params}`);
	},
	// 保存号牌
	GetSaveCode(params){
		return axios.get(`${base.webapi}/api/Customer/GetSaveCode?${params}`);
	},
	// 初始化主持人号牌
	GetTsUserAuctionCode(params){
		return axios.get(`${base.webapi}/api/Customer/GetTsUserAuctionCode?${params}`);
	},
	// 设置主持人号牌
	SetUserCodePlatform(params) {
		return axios.post(`${base.webapi}/api/Customer/SetUserCodePlatform`,params);
	},
	// 保存主持人号牌
	SaveTsUserAuctionCode(params) {
		return axios.get(`${base.webapi}/api/Customer/SaveTsUserAuctionCode?${params}`);
	},
	// 获取原始阶梯
	GetListLadderWithCache(params) {
		return axios.get(`${base.webapi}/api/ElectronicLot/GetListLadderWithCache?${params}`);
	},
	// 互动拍提交成功流拍
	SaveAuctionRecord(params) {
		return axios.post(`${base.webapi}/api/ElectronicLot/SaveAuctionRecord`,params);
	},
	// 互动拍切拍品
	NextLot(params) {
		return axios.post(`${base.webapi}/api/Synchronization/NextLot`,params);
	},
	// 互动拍重拍
	ReBidedLot(params) {
		return axios.post(`${base.webapi}/api/Synchronization/ReBidedLot`,params);
	},
	// 同步拍详情
	GetHttableDetail(params) {
		return axios.post(`${base.webapi}/api/Httable/GetHttableDetail`,params);
	},
	// 同步拍专场列表
	GetList(params) {
		return axios.post(`${base.webapi}/api/ElectronicLot/GetList`,params);
	},
	// 更新动画速度
	UpdateLotSetting(params) {
		return axios.post(`${base.webapi}/api/Httable/UpdateLotSetting`,params);
	},
	// 
	GetUserEntrustedList(params) {
		return axios.get(`${base.webapi}/api/EntrustedRecord/GetUserEntrustedList?${params}`);
	},
	
	
	
	







	// LED屏幕接口 【screen】
	// 保存公司设置
	SaveCompany(params) {
		return axios.post(`${base.screen}/api/WebScreen/SaveCompany`,params);
	},
	// 获取公司样式列表
	GetCompanyList(params) {
		return axios.get(`${base.screen}/api/WebScreen/GetCompanyList/${params}`);
	},
	// 获取公司单个样式
	GetCompany(params) {
		return axios.get(`${base.screen}/api/WebScreen/GetCompany/${params}`);
	},
	// 删除公司样式
	RemoveCompany(params) {
		return axios.get(`${base.screen}/api/WebScreen/RemoveCompany/${params}`);
	},











	// 后台相关接口 【dzhtb】
	// 更改细节图排序
	UpdateHttableDetailSort(params,config) {
		return axios.post(`${base.dzhtb}/api/Httable/UpdateHttableDetailSort`,params,config);
	},
	// 删除细节图
	RemoveHttableDetail(params,config) {
		return axios.post(`${base.dzhtb}/api/Httable/RemoveHttableDetail`,params,config);
	}
	
}

export default webapi;
