import Vue from 'vue';
import Router from 'vue-router';
// 使用Vue Router插件
Vue.use(Router);
 
// 创建Router实例并配置路由
export default new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/loginCompany' },
    // 公司登录
    {
      path: '/loginCompany',
      name: 'loginCompany',
      component: () => import("@/components/shouye/loginCompany")
    },
    // 主页
    {
      path: '/home',
      name: 'home',
      component: () => import("@/components/shouye/home"),
      children: [
        // 公司基本信息设置
        {
          path: 'settingCompany',
          name: 'settingCompany',
          component: () => import("@/components/shezhi/settingCompany")
        },
        // 运营管理
        {
          path: 'companyRule',
          name: 'companyRule',
          component: () => import("@/components/yunyingGuanli/companyRule")
        },
      ]
    },

    // 详情图拖拽排序
    {
      path: '/dragPage',
      name: 'dragPage',
      component: () => import("@/components/zhuanchang/dragPage")
    },
    // 互动拍主持人
    {
      path: '/zbpaiHost',
      name: 'zbpaiHost',
      component: () => import("@/components/paimaiTouying/zbpaiHost")
    },
    // 同步拍主持人
    {
      path: '/LiveHostBroad',
      name: 'LiveHostBroad',
      component: () => import("@/components/paimaiTouying/LiveHostBroad")
    },
    
    // 同步拍/互动拍拍卖会专场
    {
      path: '/newZcList',
      name: 'newZcList',
      component: () => import("@/components/zhuanchang/newZcList")
    },
    // 拍卖师屏
    {
      path: '/auctioneerScreen',
      name: 'auctioneerScreen',
      component: () => import("@/components/paimaiTouying/auctioneerScreen")
    },
    // 直播背景
    {
      path: '/liveBackground',
      name: 'liveBackground',
      component: () => import("@/components/paimaiTouying/liveBackground")
    },
    // LED投影
    {
      path: '/touyingPage',
      name: 'touyingPage',
      component: () => import("@/components/paimaiTouying/touyingPage")
    },
    


    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    // 但页面无导航
    // 公司基本信息设置
    {
      path: '/settingCompany',
      name: 'settingCompany',
      component: () => import("@/components/shezhi/settingCompany")
    },
    // 运营管理
    {
      path: '/companyRule',
      name: 'companyRule',
      component: () => import("@/components/yunyingGuanli/companyRule")
    },
    // 但页面无导航
  ]
});