import Vue from 'vue'
import router from './router'
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'
import api from './api/' // 导入api接口
import store from './store'
import qs from 'qs'

Vue.use(ElementUI);
Vue.prototype.axios = axios
Vue.config.productionTip = false
Vue.prototype.$api = api // 将api挂载到vue的原型上复制代码然后我们可以在页面中这样调用接口
Vue.prototype.qs = qs //引入qs 为了解决axios的post请求问题

// 千分符过滤器
Vue.filter('qianfenfu', function (num) {
  if(num == "" || !num  || num == null || num == 0) {
    return 0
  }
  var re;
  if (num.toString().indexOf('.') !== -1) {
    re = parseFloat(num).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:\.))/g, "$1,");
  } else {
    re = num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
  return re
})

new Vue({
  router,  // 在Vue实例中注册路由
  render: h => h(App),
  store
}).$mount('#app')
