/**
 * 接口域名的管理
 */
const base = {    
    webapi: 'https:/webapi.n21ce.com',    
    dzhtb: 'https://dzhtb.n21ce.com',
    screen: 'https://screen.n21ce.com'
}

export default base;
